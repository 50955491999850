import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Collapse, Grid, useMediaQuery } from '@material-ui/core'

import Close from '@tdcerhverv/parrotfish/dist/icons/Icons/Close.svg'

import { IAlertBanner, IMarkdownText } from './AlertBanner.types'
import RichText, { IRichText } from '../RichText'
import {
  getSessionStorage,
  setSessionStorage,
} from '../../utils/sessionStorage'

const useStyles = makeStyles({
  alertWrapper: {
    marginBottom: '48px',
    marginTop: '15px',
  },
  alertGrid: {
    '& >.MuiGrid-item': {
      paddingLeft: '0',
    },
  },
  alertPadding: {
    paddingRight: '32px',
  },
})

const AlertBanner = ({ inline = false, ...props }: IAlertBanner) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))

  const data = props
  const { body, severity, inlineAlert, title, description } = data
  /* this inlineAlert prop differentiates between inline alerts (simple notifications without 
  interaction )
  and alerts with close icons (dismissible alerts for user interaction)*/
  const markdownText =
    (description as IMarkdownText)?.description || (description as string)
  const bannerId = data?.id
  const [alertClosed, setAlertClosed] = useState<boolean>(false)
  const classes = useStyles()

  useEffect(() => {
    const currentSession = getSessionStorage('alertBanner-' + bannerId)
    if (currentSession) {
      setAlertClosed(true)
    }
  }, [data])

  const handleAlertClose = () => {
    setAlertClosed(true)
    setSessionStorage('alertBanner-' + bannerId, 'true')
  }

  return inlineAlert ? (
    <Grid container spacing={1} className={classes.alertWrapper}>
      <Grid item className={classes.alertGrid} md={12} xs={12}>
        <Alert
          severity={severity || 'info'}
          className={classes.alertPadding}
          variant="filled"
        >
          {body && <RichText {...(body as IRichText)} />}
          {title && <AlertTitle>{title}</AlertTitle>}
          {description && (
            <ReactMarkdown
              components={{
                a: ({ children, href }) => {
                  const url = href && href.replace(/(^\w+:|^)\/\//, '')
                  return (
                    <Tooltip title={url}>
                      <Link href={href}>{children}</Link>
                    </Tooltip>
                  )
                },
              }}
              children={markdownText}
            />
          )}
        </Alert>
      </Grid>
    </Grid>
  ) : (
    <Collapse in={!alertClosed}>
      <Alert
        className={classes.alertPadding}
        variant={isMobile || inline ? 'filled' : 'standard'}
        severity={severity || 'info'}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              handleAlertClose()
            }}
          >
            <SvgIcon
              component={Close}
              fontSize="inherit"
              viewBox={'0 0 48 48'}
            />
          </IconButton>
        }
      >
        {body && <RichText {...(body as IRichText)} />}{' '}
      </Alert>
    </Collapse>
  )
}

export default AlertBanner
