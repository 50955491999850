import create, { PartialState } from 'zustand';
import { IS_BROWSER } from '../../../../constants';
import { useFeatures } from '../../../helpers/feature-toggle';
import { requests } from '../../../service/requests';
import { LocalizedBasket } from '../../../types/generated/api.interface';
import { addToLines } from '../actions/add-to-lines';
import { removeLine } from '../actions/remove-line';
import { setAmount } from '../actions/set-amount';
import { getBasketFromStorage, setBasketToStorage, subscribeToExternalChanges } from '../helpers/basket-storage';
import { createBasket, createNoActionBasketStub } from '../helpers/create-basket';
import { Basket, BasketActions } from '../model/basket-model';
import { getBasket as getBasketAPI, putBasket as putBasketAPI } from '../service/basket-service';

let cancelPutBasket: () => void | undefined;

export const useBasket = create<Basket>((setBasket, getBasket) => {
    const features = useFeatures();

    if (!features.basket || !IS_BROWSER) {
        return createNoActionBasketStub();
    }

    const actions: BasketActions = {
        addToBasket: (productId: string, amountToAdd: number) =>
            update({ lines: addToLines(getBasket().lines, productId, amountToAdd) }),
        setAmount: (productId: string, amount: number) =>
            update({ lines: setAmount(getBasket().lines, productId, amount) }),
        removeFromBasket: (productId: string) => update({ lines: removeLine(getBasket().lines, productId) }),
        setAddressInfo: (data) => update(data),
        openMiniBasket: () => setBasket({ miniBasketOpen: true }),
        closeMiniBasket: () => setBasket({ miniBasketOpen: false }),
        clearErrors: () => setBasket({ unknownError: undefined, invalidVariants: undefined }),
    };

    const treatError = (err: unknown) => {
        const treatableError = requests.getTreatableError(err);
        const { miniBasketOpen } = getBasket();
        if (treatableError?.basket) {
            setBasket(
                {
                    ...treatableError.basket,
                    ...actions,
                    loading: false,
                    initializing: false,
                    invalidVariants: treatableError.invalidVariants,
                    miniBasketOpen,
                    voucherCodeError: undefined
                },
                true,
            );
            setBasketToStorage(treatableError.basket);
        } else if (treatableError?.errorMessage) {
            setBasket({ voucherCodeError: treatableError.errorMessage, unknownError: true, miniBasketOpen });
        } else {
            setBasket({ unknownError: true, miniBasketOpen });
        }
    };

    const savedBasketId = getBasketFromStorage()?.id;

    const initialization = (async () => {
        if (!savedBasketId) {
            return;
        }
        try {
            const data = await getBasketAPI(savedBasketId);
            if (data.closedDate) {
                setBasket({ ...actions, loading: false, initializing: false }, true);
                setBasketToStorage({});
                return;
            }
            setBasket({ ...data, ...actions, loading: false, initializing: false }, true);
        } catch (err) {
            treatError(err);
        }
    })();

    subscribeToExternalChanges((data: LocalizedBasket) =>
        setBasket(
            { ...data, ...actions, loading: false, initializing: false, miniBasketOpen: getBasket().miniBasketOpen },
            true,
        ),
    );

    const putBasket = async (basket: Basket): Promise<LocalizedBasket> => {
        const { id, lines = [], cvr = '', email, phone, attention, voucherCode } = basket;
        if (cancelPutBasket) {
            cancelPutBasket();
        }
        const putRequest = putBasketAPI({
            id,
            lines: lines.map(({ variantId, amount }) => ({ variantId, amount })),
            cvr,
            email,
            phone,
            attention,
            voucherCode,
        });
        cancelPutBasket = putRequest.cancel;
        const response = await putRequest.request;
        return response.data;
    };

    const update = async (update: PartialState<Basket, keyof LocalizedBasket>) => {
        await initialization;
        const basket = getBasket();
        const newBasket = { ...basket, ...update, loading: true };
        setBasket(newBasket);
        try {
            const data = await putBasket(newBasket);
            const { miniBasketOpen } = getBasket();
            if (data.closedDate) {
                setBasket({ ...actions, miniBasketOpen }, true);
                setBasketToStorage({});
                return;
            }
            setBasket({ ...data, ...actions, loading: false, miniBasketOpen }, true);
            setBasketToStorage(data);
        } catch (err) {
            if (!requests.isCancel(err)) {
                treatError(err);
            }
        }
        return;
    };

    return createBasket(actions, !savedBasketId);
});
