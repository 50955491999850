import React, { ReactElement } from 'react';
import { PriceTableRender } from './PriceTableRender';
import { useBasket } from '../../modules/basket';

export const BasketPriceTable = (): ReactElement => {
    const basket = useBasket();
    return (
        <PriceTableRender
            subTotal={basket.formattedTotalExcludingVatAndFreight}
            discountAmount={basket.formattedDiscountAmount}
            freight={basket.formattedFreight}
            totalInclVat={basket.formattedTotalInclVat}
            vat={basket.formattedVat}
        />
    );
};
