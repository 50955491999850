import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BasketModifiedResponse } from '../types/generated/api.interface';

export type CancellableRequest<T> = {
    cancel: () => void;
    request: Promise<T>;
};

/**
 * Wrapper for fetch requests
 */
export const requests = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    cancellablePut: <T = unknown, R = AxiosResponse<T>>(
        url: string,
        data?: unknown,
        config?: AxiosRequestConfig,
    ): CancellableRequest<R> => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const request = axios.put<T, R>(
            url,
            data,
            config ? { ...config, cancelToken: source.token } : { cancelToken: source.token },
        );
        const cancel = () => source.cancel();
        return { cancel, request };
    },
    cancellableGet: <T = unknown, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig,
    ): CancellableRequest<R> => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const request = axios.get<T, R>(
            url,
            config ? { ...config, cancelToken: source.token } : { cancelToken: source.token },
        );
        const cancel = () => source.cancel();
        return { cancel, request };
    },
    isCancel: (reason: unknown): boolean => axios.isCancel(reason),
    getTreatableError: (error: unknown): BasketModifiedResponse | undefined => {
        if (axios.isAxiosError(error) && ((error.response?.data as BasketModifiedResponse).basket || (error.response?.data as BasketModifiedResponse).errorMessage)) {
            return error.response?.data as BasketModifiedResponse;
        } else {
            return undefined;
        }
    },
};
